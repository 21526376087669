<script setup>
import { computed, inject } from 'vue';

// Componnets
import Img from '@/Components/Img.vue';
import IconListItem from '@/Components/IconListItem.vue';

// Images
import homeDesktopPlaceHolderEn from '@/../img/home/home-banner-image-desktop-en.png?metadata&webp';
import homeDesktopPlaceHolder from '@/../img/home/home-banner-image-desktop.png?metadata&webp';
import homePlaceHolderEn from '@/../img/home/home-banner-image-en.png?metadata&webp';
import homePlaceHolder from '@/../img/home/home-banner-image.png?metadata&webp';
import { usePage } from '@inertiajs/vue3';
import { useI18n } from 'vue-i18n';
import cart from '@i/cart-pink.svg';
import clock from '@i/clock-pink.svg';
import money from '@i/money-pink.svg';
import phone from '@i/phone-pink.svg';

const route = inject('route');
const { t } = useI18n();

const currentPage = usePage().props.route.current;

const isEmployerPage = computed(() => !!currentPage.includes(route('public.pages.show', { page: t('employers') })));
</script>

<template>
    <div class="container relative">
        <div class="items-center gap-8 pb-20 pt-10 md:flex">
            <div class="md:1/2 mb-10 md:mb-0 lg:w-2/5">
                <h1
                    class="mb-8 text-center text-[36px] uppercase leading-9 md:text-left md:text-[48px] md:leading-11"
                    v-html="
                        $t('The platform {bold} work in the supermarket', {
                            bold: `<span class='&quot;font-bold&quot;'>${$t('for flexible')}</span>`,
                        })
                    "
                />

                <div
                    v-if="isEmployerPage"
                    class="text-base md:text-2xl"
                >
                    {{
                        $t('Are you looking for employees to make your (flexible) workforce even stronger? Flex@ble takes care of it! We make sure you have your workforce in order in a simple & transparent way!')
                    }}
                </div>

                <ul v-else>
                    <IconListItem
                        :icon="clock"
                        :item="$t('Decide where and when you work')"
                    />
                    <IconListItem
                        :icon="phone"
                        :item="$t('Easily claim a shift via our app')"
                    />
                    <IconListItem
                        :icon="money"
                        :item="$t('Start working from 16 years!')"
                    />
                    <IconListItem
                        :icon="cart"
                        :item="$t('Get paid weekly before the weekend')"
                    />
                </ul>
            </div>
            <div class="md:1/2 lg:w-3/5">
                <Img
                    :image="homePlaceHolderEn"
                    :image-n-l="homePlaceHolder"
                    alt="flexable"
                    class="lg:hidden"
                    min-width="350px"
                />

                <Img
                    :image="homeDesktopPlaceHolderEn"
                    :image-n-l="homeDesktopPlaceHolder"
                    alt="flexable"
                    class="hidden lg:block"
                    min-width="350px"
                />
            </div>
        </div>
        <img
            :alt="$t('Pointing arrow to steps')"
            class="absolute -bottom-12 left-1/2 hidden xl:block"
            src="@/../img/home/big-white-arrow.svg"
        >
    </div>
</template>
